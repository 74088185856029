<template>
    <div class="flex flex-col flex-1 overflow-hidden">
        <PageTitle title="Flexibility Details" :loading="loading">
            <div class="flex items-center space-x-6">
                <router-link to="/overview">
                    <TwButton variant="light"
                        ><chevron-left-icon class="w-5 h-5 mr-2" />Back to Flexibility Search</TwButton
                    >
                </router-link>
                <template v-if="isAggregator && asset && !assetsInContracts.includes(asset.id)">
                    <TwButton variant="red" v-if="cartAssetIds.includes(parseInt(id, 10))" @click="removeFromCart(id)">
                        <svg class="mr-2" width="1em" height="1em" viewBox="0 0 512 512">
                            <path
                                fill="white"
                                d="M459.26 96L328 225.7V96h-34.525L168 223.267V16H16v480h480V96ZM464 464H48V48h88v216h36.778L296 139.018V264h38.764L464 136.3Z"
                            ></path>
                            <path
                                fill="white"
                                d="M136 328v8h32v-32h-32v24zm0 48h32v32h-32zm80-48v8h32v-32h-32v24zm0 48h32v32h-32zm80-48v8h32v-32h-32v24zm0 48h32v32h-32zm80-72h32v32h-32zm0 72h32v32h-32z"
                            ></path>
                        </svg>

                        Remove from Cluster</TwButton
                    >
                    <TwButton variant="secondary" @click="addToCart(asset)" :disabled="!canBuy" v-else>
                        <svg class="mr-2" width="1em" height="1em" viewBox="0 0 512 512">
                            <path
                                fill="white"
                                d="M459.26 96L328 225.7V96h-34.525L168 223.267V16H16v480h480V96ZM464 464H48V48h88v216h36.778L296 139.018V264h38.764L464 136.3Z"
                            ></path>
                            <path
                                fill="white"
                                d="M136 328v8h32v-32h-32v24zm0 48h32v32h-32zm80-48v8h32v-32h-32v24zm0 48h32v32h-32zm80-48v8h32v-32h-32v24zm0 48h32v32h-32zm80-72h32v32h-32zm0 72h32v32h-32z"
                            ></path>
                        </svg>

                        Add to Cluster</TwButton
                    >
                </template>
            </div>
            <template #status>
                <div
                    class="px-2 py-1 ml-4 text-sm text-white capitalize rounded-full bg-secondary-700"
                    v-if="cartAssetIds.includes(parseInt(id, 10))"
                >
                    In Cluster
                </div>
                <div
                    class="px-2 py-1 ml-4 text-sm capitalize rounded-full bg-primary-100 text-primary-500"
                    v-if="asset && assetsInContracts.includes(asset.id)"
                >
                    In Contract
                </div>
            </template>
        </PageTitle>
        <div class="px-8 py-6 bg-white shadow-md" v-if="!loading">
            <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-5">
                <div>
                    <div class="font-medium tracking-wide text-neutral-700">Flexibility</div>
                    <div class="flex items-center w-full space-x-2 text-neutral-500">
                        <span class="truncate">{{ asset.title }}</span>
                        <information-circle-icon
                            class="w-5 h-5 text-neutral-400"
                            v-tooltip="asset.description"
                            v-if="asset.description"
                        />
                    </div>
                </div>
                <div>
                    <div class="font-medium tracking-wide text-neutral-700">Provider</div>
                    <div class="text-neutral-500">
                        <span v-if="asset.provider.orgBusinessName">{{ asset.provider.orgBusinessName }}</span>
                        <span v-else>{{ asset.provider.firstName }} {{ asset.provider.lastName }}</span>
                    </div>
                </div>
                <div>
                    <div class="font-medium tracking-wide text-neutral-700">Device Type</div>
                    <div class="text-neutral-500">{{ asset.type }}</div>
                </div>
                <div>
                    <div class="font-medium tracking-wide text-neutral-700">Flexibility Capacity</div>
                    <div class="text-neutral-500">{{ asset.flexibilityCapacity }} KW</div>
                </div>
                <div>
                    <div class="font-medium tracking-wide text-neutral-700">Nominal Power</div>
                    <div class="text-neutral-500">{{ asset.nominalPower }} KW</div>
                </div>
            </div>
        </div>
        <div class="flex flex-1 overflow-hidden" v-if="!loading">
            <div class="flex flex-col flex-grow">
                <div class="flex flex-col flex-1 p-8 overflow-auto" v-if="asset">
                    <Form
                        :validation-schema="validationSchema"
                        ref="form"
                        class="p-6 bg-white border rounded-md border-neutral-200"
                    >
                        <div
                            class="flex items-center justify-between mb-4 text-sm font-semibold tracking-wide uppercase text-neutral-500"
                        >
                            <span> Flexibility Availability Details </span>
                            <div v-if="!isAggregator">
                                <TwButton size="sm" @click="enableEditing" v-if="!editing">
                                    <pencil-alt-icon class="w-5 h-5 mr-2" />Edit
                                </TwButton>
                                <div class="flex space-x-4" v-else>
                                    <TwButton variant="light" size="sm" @click="cancelEditing">
                                        <x-icon class="w-5 h-5 mr-2" />Cancel
                                    </TwButton>
                                    <TwButton size="sm" @click="save">
                                        <save-icon class="w-5 h-5 mr-2" />Save
                                    </TwButton>
                                </div>
                            </div>
                        </div>
                        <div class="flex space-x-4" v-show="editing">
                            <div class="w-1/2 lg:w-2/12">
                                <div class="mb-1 text-sm font-medium tracking-wide text-neutral-600">
                                    Flexibility Availability
                                </div>
                                <switch-toggle v-model="clonedAsset.marketAvailability" />
                            </div>
                            <div class="w-1/2 lg:w-3/12">
                                <InputField
                                    name="dailyActivations"
                                    label="Daily Activations"
                                    v-model="clonedAsset.dailyActivations"
                                    type="number"
                                    :min="1"
                                />
                            </div>
                        </div>
                        <div class="flex items-center space-x-4" v-if="!editing">
                            <FormItem
                                class="w-1/2 lg:w-3/12"
                                label="Flexibility Availability"
                                :value="clonedAsset.marketAvailability ? 'Visible' : 'Hidden'"
                            />
                            <FormItem
                                class="w-1/2 lg:w-2/12"
                                :class="{ 'opacity-60': !clonedAsset.marketAvailability }"
                                label="Daily Activations"
                                :value="clonedAsset.dailyActivations"
                                v-if="!R.isNil(clonedAsset.dailyActivations)"
                            />
                        </div>
                        <div
                            class="mt-8"
                            v-if="
                                clonedAsset.weekDaysAndTimes &&
                                    clonedAsset.weekDaysAndTimes.length > 0 &&
                                    clonedAsset.marketAvailability
                            "
                            :class="{ 'opacity-60': !clonedAsset.marketAvailability && !editing }"
                        >
                            <div
                                class="flex items-center my-2 space-x-4 text-sm font-medium tracking-wide text-neutral-600"
                            >
                                <div class="w-2/12">Schedule #</div>
                                <div class="w-3/12">Week Day</div>
                                <div class="w-2/12">Start Time</div>
                                <div class="w-2/12">End Time</div>
                                <div class="w-2/12"></div>
                            </div>
                            <div
                                class="flex items-center mb-2 space-x-4"
                                v-for="(schedule, idx) in clonedAsset.weekDaysAndTimes"
                                :key="idx"
                            >
                                <div class="w-2/12 text-neutral-700">Schedule {{ idx + 1 }}</div>
                                <template v-if="editing">
                                    <SelectMenu
                                        class="w-3/12"
                                        placeholder="Select week day"
                                        name="weekDay"
                                        id="weekDay"
                                        :options="weekDayOptions"
                                        v-model="schedule.weekDay"
                                    />
                                    <Calendar
                                        class="w-2/12"
                                        name="startTime"
                                        :uid="`startTime${idx}`"
                                        v-model="schedule.startTime"
                                        placeholder="Start Time"
                                        mode="time"
                                    />
                                    <Calendar
                                        class="w-2/12"
                                        name="endTime"
                                        :uid="`endTime${idx}`"
                                        v-model="schedule.endTime"
                                        placeholder="End Time"
                                        mode="time"
                                    />
                                    <a
                                        href="javascript:void(0)"
                                        class="w-2/12 text-red-500 hover:text-red-600"
                                        v-if="idx !== 0"
                                        @click="removeSchedule(idx)"
                                    >
                                        <trash-icon class="w-5 h-5" />
                                    </a>
                                </template>
                                <template v-else>
                                    <FormItem class="w-3/12" :value="schedule.weekDay" />
                                    <FormItem class="w-2/12" :value="schedule.startTime" />
                                    <FormItem class="w-2/12" :value="schedule.endTime" />
                                </template>
                            </div>
                            <div class="flex justify-end mt-4" v-if="editing">
                                <TwButton variant="light" @click="addSchedule">
                                    <plus-icon class="w-5 h-5 mr-2" /> Add Schedule
                                </TwButton>
                            </div>
                        </div>
                        <div class="mt-8 italic text-neutral-500" v-else>
                            No flexibility availability schedules defined
                        </div>
                    </Form>
                    <div class="flex flex-1" v-if="!loadingAssetDetails && series.length > 0">
                        <div class="flex flex-col flex-grow">
                            <div class="flex flex-col flex-1 p-8 ">
                                <div class="p-6 bg-white border rounded-md border-neutral-200">
                                    <div class="mb-4 text-sm font-semibold tracking-wide uppercase text-neutral-500">
                                        Flexibility Timeline
                                    </div>
                                    <apexchart type="line" height="300" :options="options" :series="series"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col items-center justify-center flex-grow" v-else-if="loadingAssetDetails">
                        <fulfilling-bouncing-circle-spinner
                            class="ml-4"
                            :animation-duration="3000"
                            :size="40"
                            color="#1d746a"
                        />
                        <span class="mt-4 text-neutral-500">Loading device flexibility data. Please wait...</span>
                    </div>
                    <div class="flex flex-col justify-center flex-grow" v-else-if="series.length === 0">
                        <div class="content-center max-w-md px-8 mx-auto">
                            <svg-image class="p-4 text-primary-600" src="/img/empty.svg" />
                        </div>
                        <div class="mx-4">
                            <h1 class="mt-4 text-xl font-medium text-center text-primary-900 md:text-xl sm:mt-8">
                                The specific device has no flexibility data at the moment.
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import * as R from 'ramda';
import { FulfillingBouncingCircleSpinner } from 'epic-spinners';
import { PageTitle, SwitchToggle, TwButton, SelectMenu, Calendar, FormItem, InputField, SvgImage } from '@/components';
import VueApexCharts from 'vue3-apexcharts';
import { ChevronLeftIcon, PencilAltIcon, XIcon, SaveIcon, TrashIcon } from '@heroicons/vue/outline';
import { InformationCircleIcon, PlusIcon } from '@heroicons/vue/solid';
import { AssetsAPI } from '@/api';
import { useCart, useUtils } from '@/composables';
import { useToast } from 'vue-toastification';
import store from '@/store';
import { weekDayOptions } from '@/views/contract/constants';
import { useRouter } from 'vue-router';
import { Form, defineRule } from 'vee-validate';
import { required, min_value } from '@vee-validate/rules';

defineRule('required', required);
defineRule('min_value', min_value);

export default defineComponent({
    name: 'Asset Details',
    props: {
        id: {
            type: [Number, String],
            required: false,
        },
    },
    components: {
        PageTitle,
        Apexchart: VueApexCharts,
        SwitchToggle,
        TwButton,
        ChevronLeftIcon,
        InformationCircleIcon,
        SelectMenu,
        Calendar,
        TrashIcon,
        PlusIcon,
        FormItem,
        InputField,
        PencilAltIcon,
        XIcon,
        SaveIcon,
        Form,
        FulfillingBouncingCircleSpinner,
        SvgImage,
    },
    setup(props) {
        const toast = useToast();
        const form = ref(null);
        const { formatTime } = useUtils();
        const isBlockchainEnabled = ref(!!process.env.VUE_APP_ETH_NODE);
        const hasWallet = store.getters.hasWallet;
        const { addToCart, removeFromCart, assetsInContracts } = useCart();
        const isAggregator = store.getters.isAggregator;
        const cart = computed(() => store.state.cart);
        const cartAssetIds = computed(() => store.state.cart.map(asset => asset.id));
        const asset = ref(null);
        const clonedAsset = ref(null);
        const editing = ref(false);
        const loading = ref(true);
        const loadingAssetDetails = ref(true);
        const router = useRouter();

        AssetsAPI.get(props.id)
            .then(res => {
                if (isAggregator && res.data && !res.data.marketAvailability) {
                    toast.error('You have no access to this flexibility');
                    router.push('/overview');
                }
                asset.value = res.data;
                clonedAsset.value = R.clone(res.data);
                if (!clonedAsset.value.weekDaysAndTimes) clonedAsset.value.weekDaysAndTimes = [];
                loading.value = false;

                AssetsAPI.getDetails(props.id)
                    .then(res => {
                        const upwardsData = [];
                        const downwardsData = [];
                        const data = R.sortBy(R.prop('timestamp'))(res.data);
                        data.forEach(row => {
                            if (row.upwardsFlexibility !== null)
                                upwardsData.push([row.timestamp, row.upwardsFlexibility]);
                            if (row.downwardsFlexibility !== null)
                                downwardsData.push([row.timestamp, row.downwardsFlexibility]);
                        });
                        if (upwardsData.length > 0)
                            series.value.push({ name: 'Upwards Flexibility', data: upwardsData });
                        if (downwardsData.length > 0)
                            series.value.push({ name: 'Downwards Flexibility', data: downwardsData });
                        loadingAssetDetails.value = false;
                    })
                    .catch(() => (loadingAssetDetails.value = false));
            })
            .catch(() => {
                toast.error('You have no access to this flexibility');
                router.push('/overview');
            });

        const options = {
            chart: {
                type: 'area',
                stacked: false,
                height: 300,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true,
                },
                toolbar: {
                    autoSelected: 'zoom',
                },
            },
            theme: {
                mode: 'light',
                palette: 'palette2',
            },
            stroke: {
                width: 3,
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                opacity: 1,
            },
            markers: {
                size: 4,
            },
            xaxis: {
                type: 'datetime',
                title: {
                    text: 'Time',
                },
            },
            yaxis: {
                title: {
                    text: 'Flexibility (KW)',
                },
            },
            labels: {
                formatter: function(val) {
                    return val.toFixed(2);
                },
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy HH:mm',
                },
            },
            legend: {
                position: 'top',
            },
        };

        // TODO: fix this when we have the retrieval queries
        // const series = ref([])
        const series = ref([
            {
                name: 'series-1',
                data: [
                    [1633005727, 28],
                    [1633092127, 15],
                    [1633178527, 20],
                    [1633264927, 18],
                    [1633351327, 7],
                ],
            },
        ]);

        const selectedProvider = computed(() => (cart.value.length > 0 ? cart.value[0].providerId : null));

        const canBuy = computed(
            () =>
                (!isBlockchainEnabled.value || (isBlockchainEnabled.value && hasWallet)) &&
                (!selectedProvider.value ||
                    (selectedProvider.value && asset.value && asset.value.providerId === selectedProvider.value)),
        );

        const addSchedule = () => {
            clonedAsset.value.weekDaysAndTimes.push({
                weekDay: null,
                startTime: {
                    hours: null,
                    minutes: null,
                },
                endTime: {
                    hours: null,
                    minutes: null,
                },
            });
        };

        const removeSchedule = idx => {
            clonedAsset.value.weekDaysAndTimes.splice(idx, 1);
        };

        const modifySchedules = () => {
            clonedAsset.value.weekDaysAndTimes.forEach(schedule => {
                const startTimeParts = schedule.startTime.split(':');
                const endTimeParts = schedule.endTime.split(':');
                schedule.startTime = { hours: Number(startTimeParts[0]), minutes: Number(startTimeParts[1]) };
                schedule.endTime = { hours: Number(endTimeParts[0]), minutes: Number(endTimeParts[1]) };
            });
        };

        const enableEditing = () => {
            if (clonedAsset.value.weekDaysAndTimes) {
                if (clonedAsset.value.weekDaysAndTimes.length === 0) addSchedule();
                else modifySchedules();
            }
            editing.value = true;
        };

        const cancelEditing = () => {
            clonedAsset.value = R.clone(asset.value);
            form.value.resetForm();
            editing.value = false;
        };

        const invalidSchedule = schedule =>
            schedule.weekDay === null ||
            schedule.startTime.hours === null ||
            schedule.startTime.minutes === null ||
            schedule.endTime.hours === null ||
            schedule.endTime.minutes === null;

        const validateSchedules = dailyActivationsIsValid => {
            if (clonedAsset.value.marketAvailability) {
                let valid = true;
                for (let i = 0; i < clonedAsset.value.weekDaysAndTimes.length; i += 1) {
                    if (invalidSchedule(clonedAsset.value.weekDaysAndTimes[i])) {
                        valid = false;
                        break;
                    }
                }
                if (dailyActivationsIsValid && !valid && clonedAsset.value.marketAvailability)
                    toast.error('Some schedules are missing required information (Week Day/ Start Time/ End Time)');
                return valid;
            }
            return true;
        };

        const locateValidSchedules = schedules => {
            const validSchedules = [];
            for (let i = 0; i < schedules.length; i++) {
                if (!invalidSchedule(schedules[i])) {
                    validSchedules.push(schedules[i]);
                }
            }
            return validSchedules;
        };

        const save = async () => {
            const { valid } = await form.value.validate();
            if (valid && validateSchedules(valid)) {
                const payload = R.clone(clonedAsset.value);

                if (!payload.marketAvailability) {
                    payload.weekDaysAndTimes = locateValidSchedules(payload.weekDaysAndTimes);
                }
                for (let i = 0; i < payload.weekDaysAndTimes.length; i += 1) {
                    payload.weekDaysAndTimes[i].startTime = formatTime(payload.weekDaysAndTimes[i].startTime);
                    payload.weekDaysAndTimes[i].endTime = formatTime(payload.weekDaysAndTimes[i].endTime);
                }
                AssetsAPI.update(props.id, payload)
                    .then(res => {
                        asset.value = res.data;
                        clonedAsset.value = R.clone(res.data);
                        toast.success('Flexibility availability details updated!');
                        editing.value = false;
                    })
                    .catch(() => {
                        toast.error('Updating flexibility availability details failed');
                    });
            }
        };

        const validationSchema = computed(() => {
            return {
                // weekDay: 'required',
                dailyActivations: 'required|min_value:1',
            };
        });

        return {
            editing,
            weekDayOptions,
            cartAssetIds,
            isAggregator,
            asset,
            options,
            series,
            addToCart,
            removeFromCart,
            isBlockchainEnabled,
            hasWallet,
            canBuy,
            assetsInContracts,
            save,
            clonedAsset,
            enableEditing,
            addSchedule,
            removeSchedule,
            cancelEditing,
            loading,
            validationSchema,
            form,
            loadingAssetDetails,
            R,
        };
    },
});
</script>
